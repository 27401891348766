import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/amahony/Sites/reactberry/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground } from 'docz';
import { Button, Box, Heading } from '@reactberry/core';
import theme from '.';
export function Colormap() {
  return <Box display="grid" gridGap="small" gridTemplateColumns="repeat(8, 1fr)" mdxType="Box">
      {Object.keys(theme.colors).map((color, i) => color !== 'palette' && color !== 'intents' && <Box key={i} border="1px solid" borderColor="light" shadow="small" mdxType="Box">
              <Box bg={color} p="2rem" mdxType="Box" />
              <Heading fontSize="xsmall" px="xsmall" mdxType="Heading">
                {color}
              </Heading>
            </Box>)}
    </Box>;
}
export function Palettemap() {
  return <Box mdxType="Box">
      {Object.keys(theme.colors.palette).map((item, i) => <Box key={i} mdxType="Box">
          <Heading fontSize="medium" mb={0} mdxType="Heading">
            {item}
          </Heading>
          <Box display="grid" gridGap="small" gridTemplateColumns="repeat(10, 1fr)" mdxType="Box">
            {Object.values(theme.colors.palette[item]).map((data, i) => <Box flex="1 0 auto" key={i} mdxType="Box">
                <Heading fontSize="xsmall" mdxType="Heading">
                  {item}.{i}
                </Heading>
                <Box bg={data} p="2rem" mdxType="Box" />
                <Text fontSize="xsmall" mdxType="Text">{data}</Text>
              </Box>)}
          </Box>
        </Box>)}
    </Box>;
}
export function Intentsmap() {
  return <Box mdxType="Box">
      {Object.keys(theme.colors.intents).map((item, i) => <Box key={i} mdxType="Box">
          <Heading fontSize="medium" mb={0} mdxType="Heading">
            {item}
          </Heading>
          <Box display="grid" gridGap="small" gridTemplateColumns="repeat(10, 1fr)" mdxType="Box">
            {Object.values(theme.colors.intents[item]).map((data, i) => <Box flex="1 0 auto" key={i} mdxType="Box">
                <Heading fontSize="xsmall" mdxType="Heading">
                  {item}.{i}
                </Heading>
                <Box bg={data} p="2rem" mdxType="Box" />
                <Text fontSize="xsmall" mdxType="Text">{data}</Text>
              </Box>)}
          </Box>
        </Box>)}
    </Box>;
}
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Text = makeShortcode("Text");
const layoutProps = {
  Colormap,
  Palettemap,
  Intentsmap,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">






    <h2 {...{
      "id": "colors"
    }}>{`Colors`}</h2>
    <Colormap mdxType="Colormap" />
    <h2 {...{
      "id": "color-palettes"
    }}>{`Color Palettes`}</h2>
    <Palettemap mdxType="Palettemap" />
    <h2 {...{
      "id": "color-intents"
    }}>{`Color Intents`}</h2>
    <Intentsmap mdxType="Intentsmap" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      